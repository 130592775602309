import { getLocal } from '@/shared/helpers/storageHelper';
import { useData } from '@/shared/providers/DataProvider';
import { LayoutProps } from '@/shared/types/layoutTypes';
import { CookieBannerStoryblok, FooterStoryblok, NavbarStoryblok } from '@/shared/types/storyblok/storyblok-types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import AnimatedLayout from '../Animation/AnimatedLayout';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import SecondaryNavbar from '../Navbar/SecondaryNavbar';
const CookieBanner = dynamic(() => import('@/components/CookieBanner/CookieBanner'), {
    loading: () => <p>Loading Banner</p>,
});

const Layout = ({ blok, children, anchorLinks, articleNav, animationType }: LayoutProps) => {
    const router = useRouter();
    const { pageType, slugs } = useData();
    const navbarData = blok.content.components.find((comp: NavbarStoryblok) => comp.component === 'navbar');
    const footerData = blok.content.components.find((comp: FooterStoryblok) => comp.component === 'footer');
    const cookieData = blok.content.components.find((comp: CookieBannerStoryblok) => comp.component === 'cookieBanner');
    const [showCookieBanner, setShowCookieBanner] = useState<boolean>(false);
    const storedCookieConsent = useMemo(() => getLocal('cookie_consent', null), []);

    useEffect(() => {
        if (storedCookieConsent === null && !router.query._storyblok) {
            setShowCookieBanner(true);
        } else {
            setShowCookieBanner(false);
        }
    }, [router.query._storyblok, storedCookieConsent]);

    return (
        <>
            <Navbar blok={navbarData} pageType={pageType} />
            {((process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && showCookieBanner) || pageType === 'config') && (
                <CookieBanner blok={cookieData} disableClick={slugs.default === 'config'} />
            )}
            <AnimatedLayout
                pageType={articleNav?.[0] ? 'article' : 'page'}
                animtionType={animationType}
                key={blok._uid}
                innerNavbar={<SecondaryNavbar anchorLinks={anchorLinks} pageType={pageType} articleNav={articleNav} />}
            >
                <div className="content">
                    <div>{children}</div>
                    <Footer blok={footerData} />
                </div>
             
             
            </AnimatedLayout>
        </>
    );
};

export default Layout;
