import { MenuLinkStoryblok, MultilinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import styles from './SecondaryLink.module.scss';

type MenuLinkProps = {
    blok: MenuLinkStoryblok | MultilinkStoryblok;
    className?: string;
    index?: number;
    activeIndex?: number;
    mobile?: boolean;
    setActiveIndex?: (index: number) => void;
};

const SecondaryLink = ({ blok, className, index = 0, activeIndex = 0, setActiveIndex }: MenuLinkProps) => {
    const { link, name } = blok;
    const [active, setActive] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        if (link?.anchor) {
            const element = document.querySelector(`#${link.anchor}`);

            if (element) {
                observer.current = new IntersectionObserver(([entry]) => setActive(entry.isIntersecting), {
                    rootMargin: '-180px 0% -60% 0%',
                });

                observer.current.observe(element);

                return () => observer?.current?.unobserve(element);
            }
        }
    }, []); //eslint-disable-line

    useEffect(() => {
        if (active && setActiveIndex) setActiveIndex(index as any);
    }, [active, index, setActiveIndex]);
    return (
        <ScrollLink
            className={clsx(styles.link, activeIndex === index && styles.active, styles.hover, className)}
            to={link?.anchor}
            smooth={true}
            isDynamic
            offset={activeIndex < index ? -67 : -158}
            onClick={() => setActiveIndex && setActiveIndex(index as any)}
        >
            {name}
        </ScrollLink>
    );
};

export default SecondaryLink;
