import useScrollProgress from '@/shared/helpers/useScrollProgress';
import useNavbarScroll from '@/shared/providers/useNavbarScroll';
import { MenuLinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData } from '@storyblok/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ArticleNavbar from '../ArticleLayout/ArticleNavbar';
import SecondaryDesktopNavbar from '../SecondaryNavbar/Desktop/SecondaryDesktopNavbar';
import styles from './SecondaryNavbar.module.scss';

type NavbarProps = {
    anchorLinks?: MenuLinkStoryblok[];
    pageType: string;
    articleNav?: SbBlokData[];
};

const SecondaryNavbar = ({ anchorLinks, pageType, articleNav }: NavbarProps) => {
    const { navbarPosition, handlePageScroll } = useNavbarScroll(0, -90);
    const [translateY, setTranslateY] = useState(`translateY(0px)`);
    useEffect(() => {
        setTranslateY(`translateY(${navbarPosition}px)`);
    }, [navbarPosition]);

    const progress = useScrollProgress(handlePageScroll);

    return (
        <>
            <div className={styles.container}>
                {anchorLinks && anchorLinks?.length > 0 && (
                    <SecondaryDesktopNavbar
                        links={anchorLinks}
                        translateY={translateY}
                    />
                )}
            </div>
            {articleNav?.[0] && pageType !== 'page' && (
                <motion.div
                    initial={{ y: 700, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { delay: 0 } }}
                    exit={{ opacity: 0, y: -700, transition: { duration: 0.36 } }}
                >
                    <ArticleNavbar content={articleNav || []} translateY={translateY} progress={progress} />
                </motion.div>
            )}
        </>
    );
};

export default SecondaryNavbar;
