import { Variants, motion } from 'framer-motion';
interface LayoutProps {
    children: React.ReactNode;
    direction: any;
    key?: string;
}

export default function DirectionalSwipe({ children, direction, key }: LayoutProps) {
    const anim = (variants: Variants, custom: number | null = null) => ({
        initial: 'initial',
        animate: 'enter',
        exit: 'exit',
        custom,
        variants,
    });

    return (
        <motion.div key={key} {...anim(direction)}>
            {children}
        </motion.div>
    );
}
