import { FooterRowStoryblok, FooterStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { StoryblokComponent } from '@storyblok/react';
import Container from '../Container/Container';
import styles from './Footer.module.scss';
import { useMediaQuery } from 'react-responsive';
type FooterProps = {
    blok: FooterStoryblok;
};
const Footer = ({ blok }: FooterProps) => {
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 990px)' }); //tablet
    const code = isTablet ? 'standard' : 'large';
    return (
        <Container code={code} blok={blok} className={{ root: styles.root }}>
            <div className={styles.tablet}>
                {blok.rows?.map((blok: FooterRowStoryblok) => (
                    <StoryblokComponent blok={blok} key={blok._uid} />
                ))}
            </div>
        </Container>
    );
};

export default Footer;
