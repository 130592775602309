import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { AnimationType, article, page, unanimated } from './components/Animation/Anim';
import DirectionalSwipe from './components/DirectionalSwipe/DirectionalSwipe';

type AnimationProps = {
    children: React.ReactNode;
    key?: string;
    animtionType?: AnimationType;
    innerNavbar?: ReactNode;
    pageType?: string;
};

const AnimatedLayout = ({
    children,
    key,
    animtionType = 'unanimated',
    innerNavbar,
    pageType = 'page',
}: AnimationProps) => {
    const animations = {
        article,
        page,
        unanimated,
    };

    return (
        <div key={key}>
            <>
                {pageType === 'page' ? (
                    innerNavbar
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: -1000 }}
                        animate={{
                            y: 0,
                            opacity: 1,
                            transition: { duration: 1 },
                        }}
                    >
                        {innerNavbar}
                    </motion.div>
                )}
                <DirectionalSwipe direction={animations[animtionType]}>{children}</DirectionalSwipe>
            </>
        </div>
    );
};

export default AnimatedLayout;
