export type AnimationType = 'article' | 'page' | 'unanimated';

export const article = {
    initial: {
        y: '100vh',
        width: '100vw',
        scale: 0.2,
        heigth: '0',
    },
    enter: {
        y: '0',
        width: '100vw',
        heigth: '100vh',
        scale: 1,
        transition: { duration: 0.6, easeInOut: [0.76, 0, 0.24, 1] },
    },
    exit: {
        visibility: 'hidden',
        heigth: '100vh',
        width: '100vw',
        y: '-100vh',
        opacity: 0,
        transition: { duration: 0.36, easeInOut: [0.76, 0, 0.24, 1] },
    },
};

export const page = {
    initial: {
        y: '100vh',
        width: '100vw',
        opacity: 0,
        heigth: '0',
    },
    enter: {
        y: '0',
        width: '100vw',
        heigth: '100vh',
        opacity: 1,
        transition: { duration: 0.36 },
    },
    exit: {
        visibility: 'hidden',
        heigth: '100vh',
        width: '100vw',
        y: '-100vh',
        opacity: 0,
        transition: { duration: 0.36, easeInOut: [0.76, 0, 0.24, 1] },
    },
};

export const unanimated = {};
