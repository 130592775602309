import { Typography } from '@/shared';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import Link from 'next/link';
import { KeyboardEvent } from 'react';
import ProgressBar from '../_components/ProgressBar/ProgressBar';
import styles from './ArticleDesktopNavbar.module.scss';

export type LinkData = {
    anchor: string;
    title?: string;
    _uid: string;
    count: number;
};

type SecondaryNavProps = {
    translateY: string;
    progress?: number;
    backClick?: () => void;
    closeClick: () => void;
    anchorLinks: LinkData[] | [];
    activeIndex?: number;
    setActiveIndex: (index: number) => void;
};

const ArtcleDesktopNavbar: React.FC<SecondaryNavProps> = ({
    progress,
    translateY,
    backClick,
    closeClick,
    anchorLinks,
    activeIndex,
    setActiveIndex,
}) => {
    function handleKeyDown(
        e: KeyboardEvent<HTMLDivElement> | KeyboardEvent<HTMLAnchorElement>,
        index: number,
        count: number,
    ) {
        if (e.key === 'Enter' && index > 0) {
            setActiveIndex && setActiveIndex(count);
        } else if (e.key === 'Enter' && index === -1) {
            backClick?.();
        } else if (e.key === 'Enter' && index === 0) {
            closeClick();
        }
    }

    return (
        <>
            <div className={styles.container} style={{ transform: translateY }}>
                <div className={styles.secondary_nav}>
                    <div className={styles.arrowBack}>
                        {/* <Icon name="arrow_forward" onClick={backClick} /> */}
                    </div>
                    <div className={styles.anchorlinks}>
                        {anchorLinks.length > 0 &&
                            anchorLinks.map((link: LinkData, index: number) => {
                                return (
                                    <Link
                                        key={link._uid}
                                        href={`#${link?.anchor}`}
                                        onClick={() => setActiveIndex && setActiveIndex(link.count)}
                                        onKeyDown={e => handleKeyDown(e, index, link.count)}
                                    >
                                        <Typography.Small
                                            className={clsx(styles.link, link.count === activeIndex && styles.active)}
                                        >
                                            {link.anchor}
                                        </Typography.Small>
                                    </Link>
                                );
                            })}
                    </div>
                    <div
                        className={styles.btn}
                        onClick={closeClick}
                        tabIndex={0}
                        role="button"
                    >
                        <Icon name="close" />
                    </div>
                </div>
                <div className={styles.progressbar}>
                    <ProgressBar
                        backgroundColor={'var(--core80)'}
                        progressColor={'var(--core5)'}
                        completed={progress}
                    />
                </div>
            </div>
        </>
    );
};
export default ArtcleDesktopNavbar;
