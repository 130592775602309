import clsx from 'clsx';
import styles from './ProgressBar.module.scss';
type ProgressBarProps = {
    backgroundColor: string;
    progressColor: string;
    completed?: number;
    navbar?: boolean;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ backgroundColor, progressColor, completed, navbar }) => {
    return (
        <div className={clsx(styles.container, navbar && styles.navbarPadding)}>
            <div className={styles.empty} style={{ backgroundColor: backgroundColor }}>
                <div className={styles.fill} style={{ backgroundColor: progressColor, width: `${completed}%` }}></div>
            </div>
        </div>
    );
};
export default ProgressBar;
