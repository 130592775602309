import Container from '@/components/Container/Container';
import { MenuLinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { useState } from 'react';
import SecondaryLink from '../ui/SecondaryLink';
import styles from './Navbar.module.scss';

type DesktopNavbarProps = {
    links: MenuLinkStoryblok[];
    translateY?: string;
};

const SecondaryDesktopNavbar = ({ links, translateY }: DesktopNavbarProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(1);

    return (
        <div className={styles.root} style={{ transform: translateY }}>
            <Container code="large" className={{ wrap: styles.wrapper }}>
                <div className={styles.menu}>
                    {links?.map((link: MenuLinkStoryblok, index: number) => {
                        return (
                            <SecondaryLink
                                blok={link}
                                key={link._uid}
                                className={styles.link}
                                setActiveIndex={setActiveIndex}
                                index={index + 1}
                                activeIndex={activeIndex}
                            />
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default SecondaryDesktopNavbar;
