import { links } from '@/shared';
import { useData } from '@/shared/providers/DataProvider';
import { useBreakpoints } from '@/shared/providers/Device';
import { MenuLinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData } from '@storyblok/react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { navigateBack } from '../../shared/helpers/url';
import ArticleDesktopNavbar from './ArticleDesktopNavbar/ArticleDesktopNavbar';
import ArticleMobileNavbar from './ArticleMobileNavbar/ArticleMobileNavbar';

type SecondaryNavProps = {
    content: SbBlokData[];
    translateY: string;
    progress?: number;
    Navbar?: MenuLinkStoryblok[];
};

const ArticleNavbar: React.FC<SecondaryNavProps> = ({ content, progress, translateY, Navbar }) => {
    const anchorLinks = links(content, Navbar);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const { isLarge, isExtraLarge, isMedium } = useBreakpoints();
    const isInBetween = useMediaQuery({ query: '(min-width: 768px) and (max-width: 860px)' }); //tablet
    const mobile = (!isLarge && !isExtraLarge && !isMedium) || isInBetween;
    const { data } = useData();

    return (
        <>
            {!mobile ? (
                <ArticleDesktopNavbar
                    anchorLinks={anchorLinks}
                    progress={progress}
                    translateY={translateY}
                    closeClick={() => navigateBack(!!data?.lastPageSlugs)}
                    setActiveIndex={setActiveIndex}
                />
            ) : (
                <ArticleMobileNavbar
                    anchorLinks={anchorLinks}
                    progress={progress}
                    closeClick={() => navigateBack(data?.lastPageSlugs)}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
            )}
        </>
    );
};
export default ArticleNavbar;
