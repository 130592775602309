import ProgressBar from '@/components/ArticleLayout/_components/ProgressBar/ProgressBar';
import { Typography, getColor } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { MicrocopyContext } from '@/shared/providers/Microcopy/MicrocopyContext';
import { ScrollDirection, useScrollDirection } from '@/shared/providers/scrollDir';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import Link from 'next/link';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LinkData } from '../ArticleDesktopNavbar/ArticleDesktopNavbar';
import styles from './ArticleMobileNavbar.module.scss';

type SecondaryNavProps = {
    anchorLinks: LinkData[] | [];
    progress?: number;
    activeIndex?: number;
    setActiveIndex?: (index: number) => void;
    closeClick: () => void;
};
const ArticleMobileNavbar: React.FC<SecondaryNavProps> = ({
    anchorLinks,
    progress,
    activeIndex,
    setActiveIndex,
    closeClick,
}) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [clicked, setClicked] = useState(false);
    const hexColor = useContext(ColorContext);
    const i = { '--color': getColor(hexColor, true) } as React.CSSProperties;
    const { microcopy } = useContext(MicrocopyContext);
    const scrollDir = useScrollDirection();

    const handleMenuClick = useCallback(
        (e: TouchEvent) => {
            if (menuRef.current && clicked && !menuRef.current.contains(e.target as Node)) {
                document.documentElement.classList.remove('no-scroll');
                setClicked(false);
            }
        },
        [clicked],
    );

    useEffect(() => {
        document.addEventListener('touchstart', e => handleMenuClick(e), { passive: true });
        return () => {
            document.removeEventListener('touchstart', e => handleMenuClick(e));
        };
    }, [clicked, handleMenuClick]);

    return (
        <div className={clsx(styles.container)}>
            <div className={clsx(clicked && styles.overlay)}>
                <div className={clsx(clicked && styles.progress, styles.top)} ref={menuRef}>
                    <div>
                        <ProgressBar
                            backgroundColor={clicked ? 'var(--core40)' : 'var(--core80)'}
                            progressColor={clicked ? 'var(--core20)' : 'var(--core5)'}
                            completed={progress}
                            navbar
                        />
                    </div>
                    <div className={clsx(styles.chapterNav, anchorLinks.length < 1 && styles.closeRight)}>
                        {!clicked && (
                            <>
                                {anchorLinks.length > 0 && (
                                    <div
                                        className={clsx(
                                            styles.chapt,
                                            scrollDir === ScrollDirection.up && styles.minimized,
                                        )}
                                        onClick={() => {
                                            setClicked(!clicked);
                                        }}
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={e => e.key === 'Enter' && setClicked(!clicked)}
                                        data-attribute="chapter-nav"
                                    >
                                        <Icon
                                            className={styles.chapters}
                                            name={'chapters'}
                                            data-attribute="chapter-nav"
                                        />
                                        {scrollDir === ScrollDirection.down && (
                                            <div className={styles.text}>
                                                <Typography.Small>{microcopy.chapters}</Typography.Small>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div
                                    className={clsx(
                                        styles.close,
                                        scrollDir === ScrollDirection.down && styles.minimized,
                                    )}
                                    onClick={closeClick}
                                    tabIndex={0}
                                    role="button"
                                    onKeyDown={e => e.key === 'Enter' && closeClick()}
                                >
                                    <Icon name={'close'} />
                                    {scrollDir !== ScrollDirection.down && (
                                        <div className={styles.text}>
                                            <Typography.Small>{microcopy.close}</Typography.Small>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {clicked && anchorLinks.length > 0 && (
                            <div
                                className={clsx(
                                    clicked && styles.secondarynav,
                                    clicked && styles.open,
                                    anchorLinks.length <= 1 && styles.hidden,
                                )}
                                data-attribute="chapter-nav"
                            >
                                <div
                                    className={styles.row}
                                    onClick={() => setClicked(!clicked)}
                                    onKeyDown={e => e.key === 'Enter' && setClicked(!clicked)}
                                    tabIndex={0}
                                    role="button"
                                >
                                    <div className={styles.group}>
                                        <Icon className={styles.chapters} name={'chapters'} />
                                        <Typography.Small>{microcopy.chapters}</Typography.Small>
                                    </div>
                                    <Icon className={styles.chapters} name={'close'} />
                                </div>

                                {anchorLinks.map((link: LinkData) => {
                                    return (
                                        <Link
                                            className={styles.row}
                                            key={link._uid}
                                            href={`#${link?.anchor}`}
                                            onClick={() => setActiveIndex && setActiveIndex(link.count)}
                                            style={i}
                                            data-attribute="chapter-nav"
                                        >
                                            <Typography.Small
                                                darkTheme
                                                className={clsx(
                                                    styles.title,
                                                    link.count === activeIndex && styles.active,
                                                )}
                                                data-attribute="chapter-nav"
                                            >
                                                {link.anchor}
                                            </Typography.Small>
                                            <Icon name={'chevron_left'} data-attribute="chapter-nav" />
                                        </Link>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleMobileNavbar;
